<template>
  <div>
    <div v-if="siteData.giftcardFeatureAvailable">
      <v-container>
        <v-dialog persistent v-model="noticeDialog" max-width="600">
          <v-card>
            <v-container>
              <v-card-title> Important Notice </v-card-title>
              <v-card-subtitle
                >{{ user.username }}, you need to read this</v-card-subtitle
              >
              <v-card-text>
                <p class="font-weight-bold">
                  The value you get when you sell your giftcard depends on:
                </p>
                <ul>
                  <li>The card currency</li>
                  <li>The card type</li>
                  <li>The receipt type (where receipts are required)</li>
                  <li>The value of the card</li>
                </ul>
              </v-card-text>
              <v-card-text>
                <p class="font-weight-bold">
                  Please be mindful of the following
                </p>
                <ul>
                  <li>
                    Ensure all details (Card value, Card quantity, Availability
                    of receipt, receipt type, etc.) are correct and uploaded
                    before submission. You will not be able to update or modify
                    this once a transacton has been submitted and confirmed
                  </li>
                  <li>
                    Endeavor to present all necessary receipt(s) where
                    applicable at the point of card upload as cases of
                    incomplete upload cannot be revised or reviewed.
                  </li>
                  <li>
                    Please ensure your giftcards are uploaded in the right
                    channel. Giftcards uploaded ion a wrong channel will be
                    transferred to the appropriate channel and treated at the
                    current rate in that channel.
                  </li>
                  <li>
                    Only cases/complaits of “Underpayment” or “Unconfirmed
                    cards” can be revised or reviewed.
                  </li>
                </ul>
                <p>Now you are ready to start trading.</p>
              </v-card-text>
              <v-btn
                @click.stop="noticeDialog = false"
                color="accent"
                class="d-block mx-auto"
                style="width: 60%"
                >I Agree</v-btn
              >
            </v-container>
          </v-card>
        </v-dialog>
        <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
          <v-icon left> mdi-arrow-left-thick </v-icon>
          Back
        </v-btn>
        <h1 class="mb-4">Sell Giftcard</h1>
        <p class="text-subtitle-1 font-weight-bold">
          Got questions? Call or Whatsapp {{ siteData.phone }}
        </p>
        <v-progress-circular indeterminate v-if="loading">
        </v-progress-circular>
        <v-row v-else>
          <v-col
            v-for="card in cards"
            :key="card.name"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                ripple
                :to="`/user/products/giftcards/sell/${card._id}`"
                :elevation="hover ? '8' : '2'"
                rounded="lg"
                class="pa-5 text-center"
              >
                <v-img contain :src="card.image" height="150"></v-img>
                <v-card-title class="text-center mx-auto">
                  <span class="mx-auto">{{ card.name }}</span>
                </v-card-title>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-card flat class="pa-5" rounded="lg">
        {{ siteData.giftcardFeatureMessage }}
      </v-card>
    </div>
  </div>
</template>

<script>
import giftcardApi from '../../../api/systemData'
import { mapState } from 'vuex'

export default {
  data: () => ({
    loading: false,
    cards: [],
    noticeDialog: false
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings
    })
  },
  created() {
    this.$store.dispatch('user/getSiteSettings')
    this.getAllGiftcard()
  },
  mounted() {
    this.noticeDialog = true
  },
  methods: {
    async getAllGiftcard() {
      this.loading = true
      const res = await giftcardApi.data().getData('user', { type: 'giftcard' })
      this.cards = res.data.data.data.filter((d) => d.status)
      this.loading = false
    }
  }
}
</script>

<style></style>
